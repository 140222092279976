import { Container } from 'react-bootstrap';
import { Routes, Route} from "react-router-dom";
import './App.css';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';
import ProtectedRoute from "./Protection"
function App() {
  return (
    <Container>  
      <Routes>
        <Route path="/" element={<Login/>} />
        <Route path="/dashboard" element={
          <ProtectedRoute>
            <Dashboard/>
          </ProtectedRoute>
        }/>
      </Routes>
    </Container>
  );
}

export default App;
