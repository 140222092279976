import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function ProtectedRoute({ children }) {

        const token = cookies.get(process.env.secret_phrase)
        const location = useLocation()
        if (token) {
            return children
        } else {
            return (
                <Navigate to="/" state={{from: location}}/>
            )
        }
}