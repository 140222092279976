import React, { useState } from 'react';
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default function Login() {
    const [guildID, setGuildID] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [login, setLogin] = useState(false)
    const config = {
        method: "post",
        url: "http://localhost:8000/login",
        data: {
            guildID,
            username,
            password
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        axios(config)
            .then((result) => { 
                setLogin(true)
                cookies.set(process.env.secret_phrase, result.data.token, {
                    path: "/",
                  });
                  window.location.href = `/dashboard`
            })
            .catch((err) => { err = new Error() })
    }
    return (
        <>
            <Form>
                <Form.Group controlId="formBasicGuildID">
                    <Form.Label>Guild ID:</Form.Label>
                    <Form.Control
                        name="guildID"
                        value={guildID}
                        type='text'
                        onChange={(e) => setGuildID(e.target.value)}
                        placeholder="Your server's id">
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="formBasicUsername">
                    <Form.Label>Username:</Form.Label>
                    <Form.Control
                        name="username"
                        value={username}
                        type='text'
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder='The username that was provided to you'>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password:</Form.Label>
                    <Form.Control
                        name="password"
                        value={password}
                        type='password'
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder='The password that was provided to you'>
                    </Form.Control>
                </Form.Group>
                <Button variant='primary' type='submit' onClick={(e) => handleSubmit(e)}>Login</Button>
                {login ? (
                    <p className='text-success'>You successfully logged in</p>
                ) :
                    (
                        <p className='text-danger'>You are not logged in</p>
                    )
                }
            </Form>
        </>
    )
}