import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { Form, Button } from "react-bootstrap";
const cookies = new Cookies();
const token = cookies.get(process.env.secret_phrase)
export default function Dashboard() {
    const [message, setMessage] = useState("");
    const [prefix, setPrefix] = useState("");
    const [ticketch, setTicketCH] = useState("");
    const [logch, setLogCH] = useState("");
    const [rolename, setRoleName] = useState("");
    const [channels, setChannels] = useState([]);
    const [roles, setRoles] = useState([]);
    const [color, setColor] = useState("")
    const [colors, setColors] = useState([]);
    const [cmd, setCMD] = useState("");
    const [response, setResponse] = useState("");
    const [transcript, setTranscript ] = useState("");
    const prefixconfig = {
        method: "post",
        url: "http://localhost:8000/dashboard",
        headers: {
            'Authorization': `Bearer: ${token}`,
            'Content-type': "application/json"
        },
        params: {
            token: token
        },
        data: {
            prefix
        }

    }
    const ticketconfig = {
        method: "post",
        url: "http://localhost:8000/dashboard",
        headers: {
            'Authorization': `Bearer: ${token}`,
            'Content-type': "application/json"
        },
        params: {
            token: token
        },
        data: {
            ticketch,
            rolename
        }
    }
    const logconfig = {
        method: "post",
        url: "http://localhost:8000/dashboard",
        headers: {
            'Authorization': `Bearer: ${token}`,
            'Content-type': "application/json"
        },
        params: {
            token: token
        },
        data: {
            logch,
            color
        }
    }
    const cmdconfig = {
        method: "post",
        url: "http://localhost:8000/dashboard",
        params: {token: token},
        headers: {
            'Authorization': `Bearer: ${token}`,
            'Content-type': "application/json"
        },
        data: {
            cmd,
            response
        }
    }
    const transcriptconfig = {
        method: "post",
        url: "http://localhost:8000/dashboard",
        headers: {
            'Authorization': `Bearer: ${token}`,
            'Content-type': "application/json"
        },
        params: {
            token: token
        },
        data: {
            transcript
        }
    }
    useEffect(() => {
        const config = {
            method: "get",
            url: "http://localhost:8000/dashboard",
            headers: {
                'Authorization': `Bearer: ${token}`,
                'Content-type': "application/json"
            },
            params: {
                token: token
            }
        }
        axios(config)
            .then(result => {
                setMessage(result.data.message)
                setChannels(result.data.channel)
                setColors(result.data.colors)
                setRoles(result.data.role)
            })
            .catch(err => {
                err = new Error();
            })
    }, []);
    const handlePrefixSubmit = (e) => {
        e.preventDefault();
        axios(prefixconfig)
    }
    const handleTicketSubmit = (e) => {
        e.preventDefault();
        axios(ticketconfig)
    }
    const handleLoggingSubmit = (e) => {
        e.preventDefault()
        axios(logconfig)
    }
    const handleCmdSubmit = (e) => {
        e.preventDefault()
        axios(cmdconfig)
    }
    const handleTranscriptSubmit = (e) => {
        e.preventDefault()
        axios(transcriptconfig)
    }
    return (
        <>
            <h3 className="text-center text-danger">{message}</h3>
            {/* Prefix */}
            <Form>
                <Form.Group controlId="formBasicPrefix">
                    <Form.Label>Prefix</Form.Label>
                    <Form.Control
                        name="prefix"
                        value={prefix}
                        type='text'
                        onChange={(e) => setPrefix(e.target.value)}
                        placeholder="Your desired prefix for the server">
                    </Form.Control>
                    <Button variant='primary' type='submit' onClick={(e) => handlePrefixSubmit(e)}>Update prefix</Button>
                </Form.Group>
            </Form>
            {/* Ticket Channel */}
            <Form>
                <Form.Group controlId="formBasicTicketChannel">
                    <Form.Label>Ticket</Form.Label>
                    <Form.Select name="ticketch" aria-label="Declare a channel for your ticket system" value={ticketch} onChange={(e) => setTicketCH(e.target.value)} required>
                        <option>Declare a channel for your ticket system</option>
                        {
                            channels.map(ch => (
                                <option key={ch.name}>{ch.name}</option>
                            ))
                        }
                    </Form.Select>
                    <br />
                    <Form.Select name="adminrole" aria-label="Declare a role for your ticket system"
                        value={rolename} onChange={(e) => setRoleName(e.target.value)} required>
                        <option>Declare a role for your ticket system</option>
                        {
                            roles.map(r => (
                                <option key={r.name}>{r.name}</option>
                            ))
                        }
                    </Form.Select>
                    <br />
                    <Button variant="primary" type="submit" onClick={(e) => handleTicketSubmit(e)}>Set ticket system</Button>
                </Form.Group>
            </Form>
            {/* Logging System */}
            <Form>
                <Form.Group controlId="formBasicLogsCH">
                    <Form.Label>Logging channel</Form.Label>
                    <Form.Select
                        name="logch"
                        value={logch}
                        aria-label="Declare a channel for your logging system"
                        onChange={(e) => setLogCH(e.target.value)} required>
                        <option>Declare a channel for your logging system</option>
                        {
                            channels.map(ch => (
                                <option key={ch.name}>{ch.name}</option>
                            ))
                        }
                    </Form.Select>
                    <Form.Group controlId="formBasicColor">
                        <Form.Label>Color</Form.Label>
                        <Form.Select name="color" value={color} aria-label="Declare a color for your logging system"
                            onChange={(e) => setColor(e.target.value)} required={true}>
                            <option>Select a color for your embeds</option>
                            {
                                colors.map(c => (
                                    <option key={c}>{c}</option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                    <Button variant='primary' type='submit' onClick={(e) => handleLoggingSubmit(e)}>Set logging system</Button>
                </Form.Group>
            </Form>
            {/* Custom Command */}
            <Form>
                <Form.Group controlId="formBasicCMD">
                    <Form.Label>Create custom command</Form.Label>
                    <Form.Control
                        name="cmd"
                        value={cmd}
                        onChange={(e) => setCMD(e.target.value)}
                        type="text"
                        placeholder="Your command name..."
                        required>
                    </Form.Control>
                    <Form.Control
                    name="response"
                    value={response}
                    onChange={(e) => setResponse(e.target.value)}
                    type="text"
                    placeholder="Your command's response..."
                    required>
                    </Form.Control>
                </Form.Group>
                <Button variant="primary" type="submit" onClick={(e) => handleCmdSubmit(e)}>Submit command</Button>
            </Form>
            {/* Transcipt */}
            <Form>
                <Form.Group controlId="formBasicTranscriptChannel">
                    <Form.Label>Transcript Channel</Form.Label>
                    <Form.Select aria-label="Transcript channel" 
                    name="transcriptch" 
                    value={transcript} 
                    onChange={(e) => setTranscript(e.target.value)}>
                        <option>Declare a channel for the transcript of the messages of the ticket to be sent to:</option>
                        {
                            channels.map(ch => (
                                <option key={ch.name}>{ch.name}</option>
                            ))
                        }
                    </Form.Select>
                </Form.Group>
                <Button variant="primary" type="submit" onClick={(e) => handleTranscriptSubmit(e)}>Submit transcript channel</Button>
            </Form>
        </>
    )
}